<template>
  <div>
    <v-card class="pa-4" max-width="320" min-height="300">
      <v-card-title class="panel-title"> Edit Category </v-card-title>
      <v-card-text v-if="categoryModel">
        <v-form
          ref="profileForm"
          v-model="valid"
          @submit.prevent="submit"
          action
        >
          <v-row>
            <v-col cols="12" xs="12">
              <v-text-field
                autofocus
                label="Name"
                v-model="categoryModel.name"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="text"
              ></v-text-field>
              <v-switch
                v-if="categoryModel._id"
                label="Edit Cover Photo"
                v-model="editFiles"
              ></v-switch>
              <v-file-input
                v-if="editFiles || !categoryModel._id"
                outlined
                show-size
                truncate-length="18"
                :disabled="loading"
                :rules="photoRules"
                accept="image/png, image/jpeg"
                prepend-icon="mdi-camera"
                label="Cover image"
                v-model="categoryModel.coverImg"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-row dense class="align-center justify-space-between">
            <v-col cols="12" xs="12" sm="4" class="mt-0">
              <v-btn
                :loading="loading"
                large
                color="primary"
                @click="submit"
                block
                >Save</v-btn
              >
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="mr-4 mt-0">
              <v-btn @click="$emit('close-dialog')" large outlined block
                >Close</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <!-- <v-row></v-row>
        <v-row class="mt-6 justify-end">
          <v-btn @click="$emit('close-dialog')" large raised color="primary"
            >Save</v-btn
          >
        </v-row> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "../../utils/config";
export default {
  computed: {
    ...mapGetters(["formartCurrency", "formatDateMed"]),
  },
  created() {
    setTimeout(() => {
      console.log("lkkkkk==>", this.categoryModel);
    }, 2000);
  },
  methods: {
    ...mapActions("api", ["httpGet", "httpPostMP", "httpPost"]),
    ...mapActions(["showMessage"]),
    save(date) {
      this.$refs.menu.save(date);
    },

    validate: function () {
      this.$refs.profileForm.validate();
    },
    saveCat: async function (formData) {
      let params = await this.httpPostMP({
        withAuth: true,
        url: "/admin/admin_set_category",
        data: formData,
      });
      return params.data;
    },
    updateCat: async function () {
      let params = await this.httpPost({
        withAuth: true,
        url: "/admin/admin_update_category",
        data: this.categoryModel,
      });
      return params.data;
    },
    submit: function () {
      this.validate();
      this.loading = true;
      setTimeout(async () => {
        if (!this.valid) {
          this.showMessage({
            text: "Fix fields",
            error: true,
          });
          this.loading = false;
        } else {
          try {
            if (!this.editFiles && this.categoryModel._id) {
              await this.updateCat();
            } else {
              let formData = new FormData();
              formData.append("coverImg", this.categoryModel.coverImg);
              formData.append("name", this.categoryModel.name);
              if (this.categoryModel._id) {
                formData.append("_id", this.categoryModel._id);
              }
              await this.saveCat(formData);
            }

            this.loading = false;
            this.showMessage({ text: "Category saved", error: false });
            this.$emit("refresh");
          } catch (error) {
            this.loading = false;
            let errMsg = config.errors.generic;
            this.showMessage({
              text: errMsg,
              error: true,
            });
          }
        }
      }, 100);
    },
  },
  data() {
    return {
      editFiles: false,
      search: "",
      categoryModel: {},
      menu: false,
      loading: false,
      busy: false,
      nameRules: [
        (v) => {
          if (v) {
            if (v.length > 30) {
              return "Name should be less than 30 characters";
            } else {
              return true;
            }
          } else {
            return "This Field is required";
          }
        },
      ],
      photoRules: [
        (v) => {
          if (v) {
            if (v.size < 2000000) {
              return true;
            } else {
              return "Photo size should be less than 2 MB!";
            }
          } else {
            return "This Field is required";
          }
        },
      ],
      valid: false,
    };
  },
};
</script>
<style lang="css" scoped>
.panel-title {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  font-size: 20px;
}
.text-row {
  width: 280px;
  margin-bottom: 10px;
}
.text-title {
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  color: #777;
  margin-right: 10px;
}
.text-bd {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  color: #555;
  font-size: 14px;
}
</style>

